<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Султонов Болтиҳожи Султонович<br />(1884-1919)</p></b-card-text
          >
        </b-col>
        <b-col>
          <b-card-text>
          <p class="TextBlack17">
            Султонов Болтиҳожи Султонович (1884-1919) — Қирғизистоннинг атоқли
            давлат, сиёсат ва жамоат арбоби, Ўш уйездини раҳбарининг ўринбосари
            (1918-1919), Жанубий Қирғизистонда совет ҳокимиятини ўрнатиш учун
            кураш раҳбарларидан бири, Қирғизистондаги ишчи-деҳқон милициясининг
            биринчи ташкилотчиси, Ўш шаҳар милициясининг асосчиси ва биринчи
            бошлиғи (1918 йил). Б.Султонов инқилобдан олдин Ўшда 15 январ 1887
            йили очилган рус-тузем мактабида ўқитувчи бўлиб ишлаган. Кейинчалик
            ушбу мактаб Октабр 25-йиллиги деб номланган. Б.Султонов мактаб
            қошида кутубхона очган. Ушбу кутубхона инқилобдан олдин Жанубий
            Қирғизистонда биринчи кутубхона бўлган. Болтиҳожи Султонов
            Қирғизистоннинг атоқли давлат, сиёсат ва жамоат арбоби, Ўш уйездида
            Совет ҳокимияти ўрнатиш учун курашининг фаол қатнашчиси бўлган. Ўш
            мусулмон ишчилари ва чорикор депутатлари Кенгашининг асосчиларидан
            бири бўлган (21 август 1917 йил). Туркистон ўлкаси Кенгашларининг 3
            съйезди делегати (1917 йил). Ўш уйезди солдатлари, ишчилари ва
            дехқон депутатлари Кенгашининг депутати, асосчиларидан ва
            раҳбарининг ўринбосари бўлган (16-17 январ 1918 йилда сайланган).
            Совет ҳокимияти ўрнатилгандан сўнг Ўш шаҳар милициясининг асосчиси
            ва биринчи бошлиғи (5 феврал 1918 йил), кейинчалик уйезд тергов
            комиссиясининг бошлиғи бўлган. 1918 йил 30 апрелида Туркистон AССРни
            тузган Бутунтуркистон Кенгашларининг 5 съйезди делегати. Шунингдек
            халқ таълими ва совет мактаблари тизими тузишга ва ривожланишига
            катта ҳисса қўшган. Б.С. Султоновнинг отаси Ўш шаҳар катта оқсоқоли
            ва мингбошиси Султон ва укаси Қудрат Тоирбойевлар Ўш шаҳрида
            рус-тузем мактабини очиш ташаббускорлари бўлишган. Шу билан бирга
            рус-тузем мактабини очиш учун улар пул маблағларини беришди. Ўш
            уйезди раҳбарлари маҳаллий аҳоли мактабга кетадиган ҳаражатлар учун
            пул маблағларини бериши, тузем аҳолиси ёшларни рус тилини ўрганиши
            кераклигини тушунишаётгани билан изоҳлади. Рус-тузем мактаблари —
            Туркистон ўлкаси рус маъмурияти томонидан очилган бошланғич таълим
            мактаблари еди. Бу мактабларнинг мақсади маҳаллий аҳоли ёш
            фарзандларини рус тилига ўқитиш, жаҳон ва рус маданияти билан
            таништириш, ўлка маъмуриятининг енг қуйи поғоналарига амалдорлар
            тайёрлашдан иборат еди[6]. Ушбу рус-тузем мактабларида рус тилига
            ўргатиш биринчи ўқув йилидан бошланарди. 15 январ 1887 йили Ўш шаҳар
            катта оқсоқоллари ва мингбошилари Султон ва Қудрат Тоирбойевлар[7]
            Ўш уйезди бошлиғи М.Йе. Ионов ва Туркистон ўлкаси раҳбарлари
            ёрдамида, Ўшда рус-тузем мактабини очишди. Мактабда ўша вақтда:
            мудир Орлов В.Ф., 2 та аёл ўқитувчи, муслим саводхонлиги ўқитувчиси
            Б.С. Султонов ва бир амалдор ишлашарди. Ушбу мактаб фақат бошланғич
            таълим асосларини ўқитган еди. 1915 йили маҳаллий рус-тузем мактаби
            синфлар ва ўқитувчилар сонини оширди. Aгарда олдин битта синф
            ўқитилган бўлса, январ ойидан бошлаб Туркистон ўлкаси
            генерал-губернатори розилиги билан қўшимча иккинчи синф очилди ва
            яна битта ўқитувчи Жўрахон Зайнобиддинов ишга олинди. Б.Султонов
            ўқитувчи еди, шунинг учун жанубий Қирғизистонда халқ таълимини
            ривожлантиришга мактаблар тизимини яратишга катта еътибор берган.Ўш
            вилояти халқ таълими ривожланишига катта ҳисса қўшди. 20 май 1918
            йили у Ўшда халқ таълими кенгаши тузилишида фаол иштирок етди. Унинг
            бевосита иштирокида 1918 йил августида 95 та кичкинтойга
            мўлжалланган боғча очилди, 1918 йили Ўш уйездида 13 та мактаб
            очилди, у ерда 939 та бола билим оларди. Унинг таклифи билан оч ва
            етим болалар учун бекорга овқат бериш уюштирилган. 1914 йили
            Раҳмонберди Мадазимов раҳбарлигида Ўш шаҳрида рус-тузем мактаби
            ўқитувчиси Болтиҳожи Султонов билан биргаликда театр тўгараги ташкил
            етилган. 1919 йили Болтиҳожи Султонов Ўшга бостириб кирган
            босмачилар қўлидан фожиали равишда ўлдирилди ва Ўшдаги совет
            ҳокимиятини ўрнатиш учун ҳалок бўлганлар қабрига биринчи бўлиб дафн
            етилган. Қабри Ўш шаҳар марказий скверида маданият уйини (ҳозирги
            рус черкови).
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
